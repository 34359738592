var render = function () {
  var _vm$programActive, _vm$programActive$pro, _vm$programActive2, _vm$programActive3, _vm$programActive4, _vm$programActive5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAuthenticatedAsClient,
      expression: "isAuthenticatedAsClient"
    }],
    attrs: {
      "position": "relative",
      "justify-content": "center",
      "z-index": "10"
    }
  }, [!_vm.isLoading ? _c('c-flex', {
    staticClass: "program-active",
    attrs: {
      "data-testid": "active-program",
      "w": "100%",
      "max-width": "600px",
      "bg": "#FFF",
      "flex-direction": "column",
      "padding": ['5px', '10px'],
      "gap": ['8px', '16px'],
      "border-radius": "0.5rem",
      "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "cursor": "pointer"
    },
    nativeOn: {
      "click": function click($event) {
        return _vm.onClickCardProgramActive.apply(null, arguments);
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "text-align": "center",
      "px": ['0.75rem', '1rem']
    }
  }, [_c('c-text', {
    attrs: {
      "bg": "#008C81",
      "font-weight": [400, 500],
      "font-size": ['16px', '24px'],
      "color": "#FFF",
      "border-bottom-radius": "8px",
      "py": ['4px', '4px'],
      "px": ['5px', '10px']
    }
  }, [_vm._v(" " + _vm._s(_vm.statusProgram == 'active' ? 'Program Aktif' : 'Belum Ada Program') + " ")])], 1), _vm.statusProgram != 'blank' && _vm.type == 'profile' ? _c('c-flex', {
    attrs: {
      "w": "100%",
      "px": ['0.75rem', '1rem']
    }
  }, [_c('c-image', {
    attrs: {
      "src": (_vm$programActive = _vm.programActive) === null || _vm$programActive === void 0 ? void 0 : (_vm$programActive$pro = _vm$programActive.product) === null || _vm$programActive$pro === void 0 ? void 0 : _vm$programActive$pro.photoUrl,
      "w": ['70px', '120px'],
      "object-fit": "cover",
      "mr": ['8px', '16px'],
      "border-radius": ['6px', '8px']
    }
  }), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-dir": "column",
      "justify": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": ['16px', '22px'],
      "font-weight": "700",
      "mb": "4px"
    }
  }, [_vm._v(" " + _vm._s((_vm$programActive2 = _vm.programActive) === null || _vm$programActive2 === void 0 ? void 0 : _vm$programActive2.name) + " "), _c('c-text', {
    attrs: {
      "as": "span",
      "font-size": ['12px', '18px']
    }
  }, [_vm._v(" (" + _vm._s((_vm$programActive3 = _vm.programActive) === null || _vm$programActive3 === void 0 ? void 0 : _vm$programActive3.programService) + ") ")])], 1), _c('c-text', {
    attrs: {
      "color": "#555",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "mb": "4px"
    }
  }, [_vm._v(" Tanggal aktivasi: " + _vm._s(_vm.formatDateV2((_vm$programActive4 = _vm.programActive) === null || _vm$programActive4 === void 0 ? void 0 : _vm$programActive4.startAt)) + " ")]), _c('c-text', {
    attrs: {
      "color": "#555",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500'],
      "mb": "4px"
    }
  }, [_vm._v(" Durasi: " + _vm._s((_vm$programActive5 = _vm.programActive) === null || _vm$programActive5 === void 0 ? void 0 : _vm$programActive5.duration) + " Hari ")]), _c('c-text', {
    attrs: {
      "color": "#555",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', '500']
    }
  }, [_vm._v(" Ahli gizi: " + _vm._s(_vm.nutritionistFullNameAndTitle) + " ")])], 1)], 1) : _c('c-box', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": [400, 500],
      "color": "#555",
      "px": ['0.75rem', '1rem']
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": [600, 700],
      "color": "#008C81",
      "mb": "4px"
    }
  }, [_vm._v(" Hi, " + _vm._s(_vm.fullName) + "! ")]), _vm.statusProgram === 'active' ? _c('c-box', [_c('c-text', {
    attrs: {
      "mb": "4px"
    }
  }, [_vm._v(" Saat ini kamu sedang menjalani progam "), _c('c-box', {
    attrs: {
      "as": "span",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasActiveProgram ? _vm.programActive.name : '') + " ")]), _vm._v(" nih ")], 1), _c('c-text', [_vm._v("Jangan lupa untuk selalu update progresmu yah!")])], 1) : _vm.statusProgram === 'expired' ? _c('c-box', [_c('c-text', {
    attrs: {
      "mb": "4px"
    }
  }, [_vm._v(" Program "), _c('c-box', {
    attrs: {
      "as": "span",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.hasActiveProgram ? _vm.programActive.name : '') + " ")]), _vm._v(" kamu sudah berakhir, ")], 1), _c('c-text', [_vm._v("Yuk, mulai program diet baru atau lanjutkan program lama mu dan dapatkan potongan harga promo khusus untuk mu!")])], 1) : _c('c-box', [_c('c-text', {
    attrs: {
      "mb": "4px"
    }
  }, [_vm._v(" Saat ini kamu belum mengikuti program apapun ")]), _c('c-text', [_vm._v("Yuk, mulai program dietmu sekarang!")])], 1)], 1), _c('c-box', {
    class: _vm.isShowButton && _vm.statusProgram != 'blank' ? 'extend' : '',
    attrs: {
      "as": "button",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "font-size": ['12px', '16px'],
      "px": ['0.75rem', '1rem'],
      "py": ['0.5rem', '0.5rem'],
      "cursor": "pointer",
      "background-color": _vm.isShowButton && _vm.statusProgram != 'blank' ? '#FDF9BC' : 'transparent',
      "border-top": "1px solid #F2F2F2"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleCta.apply(null, arguments);
      }
    }
  }, [_c('c-flex', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.statusProgram != 'blank',
      expression: "statusProgram != 'blank'"
    }],
    attrs: {
      "flex-direction": [_vm.type == 'profile' ? 'row' : 'column', 'row'],
      "align-items": ['baseline', 'center'],
      "flex-wrap": "wrap",
      "gap": ['0', '1']
    }
  }, [_vm.type == 'profile' ? _c('inline-svg', {
    staticClass: "icon-clock",
    attrs: {
      "src": require('@/assets/icons/icon-clock.svg'),
      "fill": "#D32737"
    }
  }) : _c('c-box', {
    attrs: {
      "as": "span",
      "color": "#D32737"
    }
  }, [_vm._v(" " + _vm._s(_vm.statusProgram == 'active' ? 'Tanggal berakhir:' : 'Akhir penawaran promo:') + " ")]), _c('c-box', {
    staticClass: "expired-date",
    attrs: {
      "as": "span",
      "color": "#555555"
    }
  }, [_vm._v(" " + _vm._s(_vm.statusProgram == 'active' ? _vm.formatDateV2(_vm.programActive.expiry) : _vm.formatDateV2(_vm.extendExpiryDate)) + " ")])], 1), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isShowButton,
      expression: "isShowButton"
    }],
    staticClass: "button",
    attrs: {
      "ml": "auto",
      "display": "flex",
      "color": "#008C81",
      "align-items": "center",
      "justify-content": "flex-end",
      "gap": "0.25rem",
      "h": "fit-content",
      "font-size": ['12px', '16px'],
      "font-weight": ['500', '500']
    }
  }, [_vm._v(" " + _vm._s(_vm.statusProgram != 'blank' ? _vm.isProgramConsultation ? 'Beli program lanjutan' : 'Perpanjang Program' : 'Lihat Program') + " "), _c('inline-svg', {
    staticClass: "icon-chevron-right",
    attrs: {
      "src": _vm.iconChevronRight
    }
  })], 1)], 1)], 1) : _c('c-flex', {
    attrs: {
      "data-testid": "active-program",
      "w": "100%",
      "max-width": "600px",
      "bg": "#FFF",
      "flex-direction": "column",
      "px": "1rem",
      "pb": ['0.5rem', '1rem'],
      "gap": "1rem",
      "border-radius": "0.5rem",
      "box-shadow": ['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "text-align": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['110px', '200px'],
      "h": ['30px', '48px'],
      "border-bottom-radius": "8px",
      "overflow": "hidden"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "font-size": ['12px', '18px'],
      "font-weight": [400, 500]
    }
  }, [_c('c-box', {
    attrs: {
      "w": "150px",
      "h": ['14px', '24px'],
      "mb": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "h": ['14px', '24px'],
      "mb": "4px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": "80%",
      "h": ['14px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "font-size": ['10px', '16px']
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['100px', '120px'],
      "h": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "w": ['130px', '165px'],
      "h": ['16px', '24px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }