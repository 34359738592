<template>
  <c-flex
    v-show="isAuthenticatedAsClient"
    position="relative"
    justify-content="center"
    z-index="10"
  >
    <c-flex
      v-if="!isLoading"
      data-testid="active-program"
      w="100%"
      max-width="600px"
      bg="#FFF"
      flex-direction="column"
      :padding="['5px', '10px']"
      :gap="['8px', '16px']"
      border-radius="0.5rem"
      :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      cursor="pointer"
      class="program-active"
      @click.native="onClickCardProgramActive"
    >
      <c-flex
        justify-content="center"
        text-align="center"
        :px="['0.75rem', '1rem']"
      >
        <c-text 
          bg="#008C81"
          :font-weight="[400, 500]" 
          :font-size="['16px', '24px']"
          color="#FFF" 
          border-bottom-radius="8px"
          :py="['4px', '4px']"
          :px="['5px', '10px']"
        >
          {{ statusProgram == 'active' ? 'Program Aktif' : 'Belum Ada Program' }}
        </c-text>
      </c-flex>

      <c-flex
        v-if="statusProgram != 'blank' && type == 'profile'"
        w="100%"
        :px="['0.75rem', '1rem']"
      >
        <c-image
          :src="programActive?.product?.photoUrl"
          :w="['70px', '120px']"
          object-fit="cover"
          :mr="['8px', '16px']"
          :border-radius="['6px', '8px']"
        />
        <c-flex
          flex-grow="1"
          flex-dir="column"
          justify="center"
        >
          <c-text
            color="primary.400"
            :font-size="['16px', '22px']"
            font-weight="700"
            mb="4px"
          >
            {{ programActive?.name }}
            <c-text
              as="span"
              :font-size="['12px', '18px']"
            >
              ({{ programActive?.programService }})
            </c-text>
          </c-text>
          <c-text
            color="#555"
            :font-size="['12px', '18px']"
            :font-weight="['400', '500']"
            mb="4px"
          >
            Tanggal aktivasi: {{ formatDateV2(programActive?.startAt) }}
          </c-text>
          <c-text
            color="#555"
            :font-size="['12px', '18px']"
            :font-weight="['400', '500']"
            mb="4px"
          >
            Durasi: {{ programActive?.duration }} Hari
          </c-text>
          <c-text
            color="#555"
            :font-size="['12px', '18px']"
            :font-weight="['400', '500']"
          >
            Ahli gizi: {{ nutritionistFullNameAndTitle }}
          </c-text>
        </c-flex>
      </c-flex>

      <c-box
        v-else
        :font-size="['12px', '18px']"
        :font-weight="[400, 500]"
        color="#555"
        :px="['0.75rem', '1rem']"
      >
        <c-text
          :font-size="['16px', '20px']"
          :font-weight="[600, 700]"
          color="#008C81"
          mb="4px"
        >
          Hi, {{ fullName }}!
        </c-text>
        <c-box v-if="statusProgram === 'active'">
          <c-text mb="4px">
            Saat ini kamu sedang menjalani progam <c-box
              as="span"
              color="#008C81"
            >
              {{ hasActiveProgram ? programActive.name : '' }}
            </c-box> nih
          </c-text>
          <c-text>Jangan lupa untuk selalu update progresmu yah!</c-text>
        </c-box>
        <c-box v-else-if="statusProgram === 'expired'">
          <c-text mb="4px">
            Program <c-box
              as="span"
              color="#008C81"
            >
              {{ hasActiveProgram ? programActive.name : '' }}
            </c-box> kamu sudah berakhir,
          </c-text>
          <c-text>Yuk, mulai program diet baru atau lanjutkan program lama mu dan dapatkan potongan harga promo khusus untuk mu!</c-text>
        </c-box>
        <c-box v-else>
          <c-text mb="4px">
            Saat ini kamu belum mengikuti program apapun
          </c-text>
          <c-text>Yuk, mulai program dietmu sekarang!</c-text>
        </c-box>
      </c-box>

      <c-box
        as="button"
        display="flex"
        justify-content="space-between"
        align-items="center"
        :font-size="['12px', '16px']"
        :px="['0.75rem', '1rem']"
        :py="['0.5rem', '0.5rem']"
        cursor="pointer"
        :background-color="isShowButton && statusProgram != 'blank' ? '#FDF9BC' : 'transparent'"
        border-top="1px solid #F2F2F2"
        :class="isShowButton && statusProgram != 'blank' ? 'extend' : ''"
        @click.stop="handleCta"
      >
        <c-flex
          v-show="statusProgram != 'blank'"
          :flex-direction="[(type == 'profile' ? 'row' : 'column'), 'row']"
          :align-items="['baseline', 'center']"
          flex-wrap="wrap"
          :gap="['0', '1']"
        >
          <inline-svg
            v-if="type == 'profile'"
            class="icon-clock"
            :src="require('@/assets/icons/icon-clock.svg')"
            fill="#D32737"
          />
          <c-box
            v-else
            as="span"
            color="#D32737"
          >
            {{ statusProgram == 'active' ? 'Tanggal berakhir:' : 'Akhir penawaran promo:' }}
          </c-box>
          <c-box
            as="span"
            color="#555555"
            class="expired-date"
          >
            {{ statusProgram == 'active' ? (formatDateV2(programActive.expiry)) : (formatDateV2(extendExpiryDate)) }}
          </c-box>
        </c-flex>
        <c-text
          v-show="isShowButton"
          ml="auto" 
          display="flex"
          color="#008C81" 
          align-items="center"
          justify-content="flex-end" 
          gap="0.25rem"
          h="fit-content"
          :font-size="['12px', '16px']"
          :font-weight="['500', '500']"
          class="button"
        >
          {{ statusProgram != 'blank' ? (isProgramConsultation ? 'Beli program lanjutan' : 'Perpanjang Program') : 'Lihat Program' }}
          <inline-svg
            :src="iconChevronRight"
            class="icon-chevron-right"
          />
        </c-text>
      </c-box>
    </c-flex>

    <!-- Skeleton -->
    <c-flex
      v-else
      data-testid="active-program"
      w="100%"
      max-width="600px"
      bg="#FFF"
      flex-direction="column"
      px="1rem"
      :pb="['0.5rem', '1rem']"
      gap="1rem"
      border-radius="0.5rem"
      :box-shadow="['0px 2px 2px rgba(17, 17, 17, 0.2)', '0px 4px 12px rgba(0, 0, 0, 0.2)']"
    >
      <c-flex
        justify-content="center"
        text-align="center"
      >
        <c-box
          :w="['110px', '200px']"
          :h="['30px', '48px']"
          border-bottom-radius="8px"
          overflow="hidden"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
          />
        </c-box>
      </c-flex>

      <c-box
        :font-size="['12px', '18px']"
        :font-weight="[400, 500]"
      >
        <c-box
          w="150px"
          :h="['14px', '24px']"
          mb="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          w="100%"
          :h="['14px', '24px']"
          mb="4px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          w="80%"
          :h="['14px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-box>

      <c-flex
        justify-content="space-between"
        :font-size="['10px', '16px']"
      >
        <c-box
          :w="['100px', '120px']"
          :h="['16px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          :w="['130px', '165px']"
          :h="['16px', '24px']"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
import iconClock from '@/assets/icons/icon-clock.svg'
import iconChevronRight from '@/assets/icon-chevron-right.svg'
import { formatDateV2 } from '@/utils/format-date'
import VueSkeletonLoader from 'skeleton-loader-vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    type: {
      type: String,
      default: 'landingpage',
    },
  },
  data() {
    return {
      isLoading: false,
      programActive: null,
      iconClock,
      iconChevronRight,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      fullName: 'auth/fullName',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    isAuthenticatedAsClient() {
      return this.isAuthenticated && this.user?.role === 'client'
    },
    hasActiveProgram() {
      return this.programActive?.name
    },
    now() {
      return moment().format('YYYY-MM-DD')
    },
    isShowButton() {
      if (this.isProgramConsultation) {
        return true
      }
      if (this.statusProgram == 'active') {
        return moment(this.programActive.expiry).subtract(7, 'd').format('YYYY-MM-DD') <= this.now
      }
      return true
    },
    extendExpiryDate() {
      if (this.hasActiveProgram) {
        return moment(this.programActive.expiry).add(30, 'd').format('YYYY-MM-DD')
      }
      return null
    },
    statusProgram() {
      if (this.hasActiveProgram) {
        if (this.programActive.expiry >= this.now) {
          return 'active'
        } else if (this.extendExpiryDate >= this.now && !this.isProgramConsultation) {
          return 'expired'
        }
      }
      return 'blank'
    },
    nutritionistFullNameAndTitle() {
      const name = `${this.programActive?.nutritionist?.firstName} ${this.programActive?.nutritionist?.lastName}`
      const title = this.programActive?.nutritionist?.education ? this.programActive?.nutritionist?.education?.map((it) => it.title).join(', ') : ''
      return `${name} ${title}`.trim()
    },
    isProgramConsultation() {
      if (this.programActive && this.programActive?.productService?.isKonsultasi) {
        return true
      } else {
        return false
      }
    },
  },
  async created() {
    if (this.isAuthenticatedAsClient) {
      this.isLoading = true
      await this.checkActiveProgram()
        .then((it) => {
          this.programActive = it
          // this.programActive = {}
          // this.programActive = {
          //   ...it,
          //   expiry: '2024-01-14',
          // }
          // this.programActive = {
          //   ...it,
          //   expiry: '2024-01-16',
          //   productService: {
          //     isKonsultasi: true,
          //   }
          // }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  methods: {
    formatDateV2,
    ...mapActions({
      checkActiveProgram: 'clients/checkActiveProgram',
      getProductServiceId: 'general/getProductServiceId',
      onUpdateCart: 'cart/onUpdateCart',
      cartList: 'cart/list',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    onClickCardProgramActive() {
      if (this.statusProgram == 'active') {
        this.$router.push({
          name: 'client.profile.programDetail',
          params: { programId: this.programActive?.id },
        })
      } else if (this.statusProgram == 'expired') {
        this.$router.push({
          name: 'client.profile.programDetail',
          params: { programId: this.programActive?.id },
        })
      } else {
        this.$router.push({
          name: 'client.select-program',
        })
      }
    },
    handleCta() {
      if (this.statusProgram == 'blank' || this.isProgramConsultation) {
        this.$router.push({ name: 'client.select-program' })
      } else if (this.isShowButton) {
        this.$router.push({ name: 'client.program.extend' })
      } else {
        this.$router.push({ 
          name: 'client.profile.programDetail',
          params: {
            programId: this.programActive?.id,
          },
        })
      }
    },
  },
}
</script>

<style scoped>
.program-active {
  transition-duration: 300ms;
}
.program-active:hover {
  background-color: #C7F9E3;
}
.program-active .extend:hover {
  transition-duration: 300ms;
  background-color: #F1E456;
}
.program-active .extend:hover .button {
  color: #008C81;
}
.program-active:has(.extend:hover) {
  background-color: #FFF !important;
}
.icon-chevron-right {
  width: 16px;
  height: 16px;
}
.icon-clock {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}
@media (min-width: 768px) {
  .icon-chevron-right {
    width: 18px;
    height: 18px;
  }
  .icon-clock {
    width: 20px;
    height: 20px;
  }
}
</style>